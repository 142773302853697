import './components/toastify';
import 'bootstrap';
import 'fslightbox';



import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const reviews = new Swiper('.reviews .swiper', {
    modules: [Navigation, Pagination],

    slidesPerView: 1,
    spaceBetween: 20,

    navigation: {
        nextEl: '.reviews .swiper-button-next',
        prevEl: '.reviews .swiper-button-prev',
    },

});

const realisations = new Swiper('.real .swiper-container', {
    modules: [Navigation, Pagination],


    // centeredSlides: true,
    // initialSlide: 1,

    navigation: {
        nextEl: '.realisations .swiper-button-next',
        prevEl: '.realisations .swiper-button-prev',
    },

    breakpoints: {

        0: {
            slidesPerView: 1,
            spaceBetween: 0,
        },

        768: {
            slidesPerView: 2,
            spaceBetween: 10,
        },

        992: {
            slidesPerView: 4,
            spaceBetween: 15,
        },
    },
});

const partners = new Swiper('.partners-swiper .swiper-container', {
    modules: [Navigation, Pagination],

        slidesPerView: '1',
        spaceBetween: 10,

        pagination: {
            el: '.partners-swiper .swiper-pagination',
            clickable: true,
        },

    breakpoints: {
        768: {
            slidesPerView: 1,
        },
    },
});

document.addEventListener('DOMContentLoaded', function () {
    var dynamicImage = document.getElementById('dynamic-image');

    function changeImage(newImagePath) {
        dynamicImage.src = newImagePath;
    }

    // Attach event listeners to each service-item
    let serviceItems = document.querySelectorAll('.service-item');

    serviceItems.forEach(function (item, index) {
        item.addEventListener('mouseenter', function () {
            changeImage(item.dataset.image);
        });
    });

    // Attach event listeners to each service-item
    let dynamicImageItems = document.querySelectorAll('.dynamic-image-button');

    dynamicImageItems.forEach(function (item, index) {
        item.addEventListener('click', function () {
            changeImage(item.dataset.image);
        });
    });
});

document.addEventListener("DOMContentLoaded", function() {
    var accordionButtons = document.querySelectorAll('.accordion-button');
    accordionButtons.forEach(function(button) {
        button.classList.add('collapsed');
    });
});

// $('#myModal').on('shown.bs.modal', function () {
//     $('#myInput').trigger('focus')
// });

document.addEventListener('DOMContentLoaded', function () {
    const serviceItems = document.querySelectorAll('.service-item');

    serviceItems.forEach(item => {
        item.addEventListener('mouseenter', function () {
            item.classList.add('expanded');
        });

        item.addEventListener('mouseleave', function () {
            item.classList.remove('expanded');
        });
    });
});


/*COUNTING*/

document.addEventListener("DOMContentLoaded", function () {
    let countingStarted = false;

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top < window.innerHeight && rect.bottom >= 0
        );
    }

    function startCounting(counter) {
        let targetText = counter.getAttribute('data-target');
        let targetMatch = targetText.match(/\d+/);
        if (targetMatch) {
            let target = parseInt(targetMatch[0]);
            // console.log('counter:', target);
            let originalText = counter.textContent;
            let originalMatch = originalText.match(/\d+/);
            if (originalMatch) {
                let originalPrefix = originalText.substring(0, originalMatch.index);
                let originalSuffix = originalText.substring(originalMatch.index + originalMatch[0].length);
                let count = 0;
                let step = Math.ceil(target / 50);
                // console.log(`counting: ${target}`);

                let counts = setInterval(function () {
                    count += step;
                    if (count >= target) {
                        clearInterval(counts);
                        counter.textContent = originalPrefix + target + originalSuffix;
                        // console.log(`Final count for target: ${target}`);
                    } else {
                        counter.textContent = originalPrefix + count + originalSuffix;
                        // console.log(`Current count: ${count} for target: ${target}`);
                    }
                }, 30);
            }
        }
    }

    function checkAndStartCounting() {
        let sectionElement = document.querySelector('.icons');
        if (sectionElement) {
            if (!countingStarted && isInViewport(sectionElement)) {
                document.querySelectorAll('.quantity').forEach(counter => {
                    startCounting(counter);
                });
                countingStarted = true;
            }
        }
    }

    document.addEventListener('scroll', checkAndStartCounting);
    window.addEventListener('resize', checkAndStartCounting);
    window.addEventListener('load', checkAndStartCounting);
});

window.openLink = function openLink(link) {
    window.location.href = link;
}

document.addEventListener("DOMContentLoaded", function () {

    document.querySelectorAll('.open-link').forEach(function(link) {
        link.addEventListener('click', function(event) {
            event.stopPropagation();
            event.preventDefault(); // Zabraňme predvolenému správaniu odkazu
            // console.log(`You clicked on: ${link.href}`);
        });
    });
});



